.root {
  @apply w-full rounded flex items-center justify-center p-2 border-[4px] border-transparent bg-white transition-all cursor-pointer select-none;
  @apply lg:p-6 lg:border-[6px];
}

.root > h6 {
  @apply text-center m-auto;
}

.root.selected {
  @apply border-orange-500;
}
