.root {
  @apply relative w-full h-full m-auto;
  @apply lg:px-32 lg:max-h-[800px];
}

.container {
  @apply relative bg-cyan-500;
  @apply min-h-[500px] h-full;
}

.progress {
  @apply w-full h-1 bg-gray-900 bg-opacity-20 absolute left-0 bottom-12;
  @apply lg:bottom-0 lg:h-2;
}

.progress .bar {
  @apply relative left-0 h-full w-[var(--progress)] bg-orange-500;
}
