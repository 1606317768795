.root {
  @apply w-full h-full grid grid-rows-[2FR_3FR];
  @apply lg:grid-cols-[2FR_3FR] lg:grid-rows-1 lg:px-20;
}

.left {
  @apply flex items-center;
}

.left > h1 {
  @apply w-full text-white uppercase text-center;
  @apply lg:text-left;
}

.right {
  @apply relative p-10 flex flex-col items-center;
  @apply lg:p-0;
}

.right > img {
  @apply relative max-h-full max-w-full;
  @apply lg:absolute lg:-bottom-[8vh];
}
