.root {
  @apply p-3;
  @apply z-50;
  @apply w-fit;
  @apply fixed;
  @apply left-0;
  @apply bottom-0;

  @apply print:hidden;
}

.root .container {
  @apply p-3;
  @apply flex;
  @apply w-fit;
  @apply gap-6;
  @apply border;
  @apply bg-white;
  @apply shadow-xl;
  @apply items-start;
  @apply rounded-full;
  @apply border-gray-200;

  @apply data-[open=true]:p-6;
  @apply data-[open=true]:w-full;
  @apply data-[open=true]:rounded-md;
}

.root .container .left {
  @apply flex;
  @apply items-center;
  @apply justify-center;
}

.root .container .left .icon {
  @apply w-6;
  @apply h-6;
  @apply cursor-pointer;
  @apply text-orange-500;
}

.root .container .content {
  @apply w-full;
  @apply mx-auto;
  @apply max-w-3xl;

  @apply data-[open=false]:hidden;
}

.root .container .content p {
  @apply mb-2;
  @apply text-sm;
  @apply text-gray-700;
}
