.root {
  @apply w-full h-full flex flex-col;
  @apply lg:pb-[12vh];
}

.header {
  @apply hidden items-center justify-center h-[20vh] min-h-[5rem];
  @apply lg:flex;
}

.header > h2 {
  @apply uppercase text-gray-600;
}
