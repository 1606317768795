@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

h1 {
  @apply
  text-3xl leading-snug font-light
  lg:text-4xl lg:leading-tight lg:font-light
  2xl:text-5xl 2xl:font-light 2xl:leading-tight;
}

h2 {
  @apply
  text-xl leading-snug font-light
  lg:text-2xl lg:leading-snug lg:font-light
  xl:text-3xl xl:leading-snug xl:font-light
  2xl:text-4xl 2xl:font-light 2xl:leading-snug;
}

h3 {
  @apply
  text-lg leading-snug font-light
  lg:text-xl lg:leading-normal lg:font-light
  xl:text-2xl xl:leading-normal xl:font-light
  2xl:text-3xl 2xl:font-light 2xl:leading-normal;
}

h4 {
  @apply
  text-base leading-normal font-light
  lg:text-lg lg:leading-normal lg:font-normal
  xl:text-xl xl:leading-normal xl:font-normal
  2xl:text-2xl 2xl:font-normal 2xl:leading-normal;
}


h5 {
  @apply
  text-sm leading-relaxed font-normal
  lg:text-base lg:leading-normal lg:font-normal
  2xl:text-base 2xl:font-normal 2xl:leading-relaxed;
}


h6 {
  @apply
  text-xs leading-relaxed font-normal
  lg:text-sm lg:leading-normal lg:font-normal
  2xl:text-sm 2xl:font-normal 2xl:leading-relaxed;
}

p {
  @apply
  text-sm leading-relaxed
  lg:text-base lg:leading-normal
  2xl:text-lg 2xl:leading-relaxed;
}

b {
  @apply
  font-semibold;
}

button {
  @apply
  text-xs leading-relaxed font-medium uppercase tracking-wider
  lg:text-xs lg:leading-normal lg:font-medium
  2xl:text-sm 2xl:leading-loose 2xl:font-medium;
}
