.root {
  @apply relative;
}

.xs {
  @apply w-3 h-3;
}

.xs .circle {
  @apply stroke-[5px];
}


.sm {
  @apply w-6 h-6;
}


.md {
  @apply w-10 h-10;
}

.svg {
  @apply absolute top-0 left-0 w-full h-full animate-rotate;
}

.circle {
  @apply animate-dash stroke-current stroke-[4px] fill-transparent;
}

.white {
  @apply stroke-white;
}
