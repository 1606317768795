.root {
  @apply z-20;
  @apply relative;
}

.root .overlay {
  @apply fixed;
  @apply inset-0;
  @apply bg-gray-100/90;
}

.root .wrapper {
  @apply fixed;
  @apply inset-0;
  @apply overflow-y-auto;
}

.root .wrapper .container {
  @apply p-4;
  @apply min-h-full;

  @apply flex;
  @apply items-center;
  @apply justify-center;
}

/* PANEL */

.root .wrapper .container .panel {
  @apply p-6;
  @apply w-full;
  @apply max-w-md;

  @apply rounded;
  @apply bg-white;
  @apply transform;
  @apply shadow-xl;
  @apply align-middle;
  @apply transition-all;
  @apply overflow-hidden;
}

.root .wrapper .container .panel .title {
  @apply text-lg;
  @apply text-left;
  @apply leading-6;
  @apply font-semibold;
  @apply text-gray-900;
}

.root .wrapper .container .panel .text {
  @apply mt-2;
  @apply text-sm;
  @apply text-left;
  @apply text-gray-500;
}

.root .wrapper .container .panel .buttons {
  @apply mt-6;

  @apply flex;
  @apply gap-2;
  @apply items-center;
  @apply justify-end;
}

.root .wrapper .container .panel .buttons .cancel {
  @apply rounded;
  @apply px-6 py-2;
  @apply outline-0;
  @apply text-white;
  @apply bg-gray-500;
  @apply outline-none;
  @apply transition-all;
  @apply hover:bg-gray-700;
}

.root .wrapper .container .panel .buttons .confirm {
  @apply rounded;
  @apply px-6 py-2;
  @apply outline-0;
  @apply text-white;
  @apply bg-cyan-500;
  @apply outline-none;
  @apply transition-all;
  @apply hover:bg-cyan-700;

  @apply flex;
  @apply gap-2;
  @apply items-center;
}

.root .wrapper .container .panel .buttons .confirm .loader {
  @apply hidden;
  @apply ml-2;
}

.label {
  @apply mt-2 px-4 py-2 bg-gray-900 rounded cursor-pointer text-white flex items-center gap-2 text-sm transition-colors;
  @apply hover:bg-gray-800;
  @apply print:hidden;
}

.root .wrapper .container .panel .buttons.loading .confirm,
.root .wrapper .container .panel .buttons.loading .cancel {
  @apply pointer-events-none;
  @apply opacity-80;
}

.root .wrapper .container .panel .buttons.loading .confirm .loader {
  @apply inline-block;
}
