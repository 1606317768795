.root {
  @apply relative hidden leading-none;
  @apply md:flex;
}

.info {
  @apply w-5 h-5 text-gray-900 flex-shrink-0 transition-all opacity-20 outline-0 outline-none cursor-pointer;
  @apply hover:text-current hover:opacity-100;
}

.panel {
  @apply hidden md:flex absolute z-10 px-4 w-52 left-7 -top-4;
  @apply sm:px-0;
  @apply lg:max-w-3xl;
}

.descriptionContainer {
  @apply bg-white overflow-hidden rounded shadow-lg ring-1 ring-black ring-opacity-5;
}

.description {
  @apply text-sm p-4 font-medium text-gray-500;
}
