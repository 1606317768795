.root {
  @apply flex flex-col gap-3 w-full min-h-[50px] h-auto;
  @apply md:gap-0 md:flex-row md:items-center;
  @apply print:grid print:grid-cols-2 print:gap-0 print:min-h-[45px];
}

.root .head {
  @apply relative flex flex-col gap-1;
  @apply md:flex-row md:flex-shrink-0 md:justify-end md:gap-3 md:pr-5 md:w-1/3 md:items-center;
  @apply print:flex-col print:items-end print:flex-shrink-0 print:pr-10 print:pb-2;
}

.root .head .title {
  @apply text-xs font-medium uppercase text-gray-500;
  @apply md:text-right;
  @apply print:text-right;
}

.root .head .description {
  @apply opacity-70;
  @apply md:hidden;
  @apply print:text-right;
}

.root .progressContainer {
  @apply relative w-full h-5 flex items-center;
  @apply lg:h-full;
  @apply print:h-full;
}

.root .progressContainer .background {
  @apply absolute bg-current opacity-10 w-full h-5 px-2 flex items-center;
}

.root .progressContainer .backgroundContent {
  @apply w-full h-5 px-2 absolute overflow-hidden flex items-center;
}

.root .progressContainer .backgroundContent span {
  @apply opacity-70 text-xs font-medium uppercase;
  @apply lg:hidden;
  @apply print:hidden;
}

.root .progressContainer .progress {
  @apply h-5 px-2 absolute bg-current flex items-center;
}

.root .progressContainer .progressContent {
  @apply h-5 px-2 absolute overflow-hidden flex items-center;
}

.root .progressContainer .progressContent span {
  @apply text-xs text-white font-medium uppercase;
  @apply lg:hidden;
  @apply print:hidden;
}

.root .progressContainer .lines {
  @apply hidden opacity-30 h-full w-full overflow-hidden absolute inset-0 grid-cols-3 divide-x divide-gray-500 border-x border-gray-500;
  @apply lg:grid;
  @apply print:grid;
}
