.root {
  @apply w-full h-fit min-h-full flex p-6 pb-20;
  @apply md:p-12 md:pb-20;
  @apply lg:p-10;
  @apply xl:p-12;
  @apply 2xl:p-16;
}

.container {
  @apply w-full min-h-full grid gap-10;
  @apply lg:grid-cols-2 lg:mx-auto;
  @apply 2xl:max-w-none;
}

.container .left {
  @apply max-h-full flex flex-col gap-4;
}

.container .left > h5 {
  @apply text-white;
}

.container .left > h4 {
  @apply text-white;
}

.container .left > h3 {
  @apply flex-grow text-white;
}

.container .left .image {
  @apply grow-[2] hidden relative max-h-[200px];
  @apply lg:flex lg:justify-end;
}

.container .left .image > img {
  @apply max-w-full max-h-[calc(100%_+_4rem_+_4vh)] absolute -bottom-[calc(4rem_+_4vh)];
}

.right {
  @apply h-full flex items-center justify-center overflow-hidden;
}

.right .options {
  @apply w-full h-full min-h-fit grid gap-4;
  @apply md:grid-cols-2 md:grid-rows-2;
}
