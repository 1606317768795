.root {
  @apply flex items-center justify-center;
  @apply rounded px-8 h-12 bg-gray-900 text-white select-none z-20;
  @apply lg:rounded-full lg:aspect-square lg:min-w-fit lg:h-auto;
}

.disabled {
  @apply pointer-events-none opacity-50;
}

.left {
  @apply fixed rounded-none w-1/2 bottom-0 left-0;
  @apply lg:rounded-r-full lg:w-auto lg:aspect-[1/2] lg:bottom-1/2 lg:transform lg:translate-y-1/2;
}

.right {
  @apply fixed rounded-none w-1/2 bottom-0 right-0 flex items-center justify-center;
  @apply lg:rounded-l-full lg:w-auto lg:aspect-[1/2] lg:bottom-1/2 lg:transform lg:translate-y-1/2;
}

.loading span {
  @apply opacity-0;
}

.root .loader {
  @apply hidden absolute;
}

.root.loading .loader {
  @apply block;
}
