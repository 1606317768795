.root {
  @apply flex;
  @apply gap-3;
  @apply items-center;
  @apply cursor-pointer;
}

.root .field {
  @apply flex;
  @apply lg:m-0.5;
}

.root .field .input {
  @apply w-0;
  @apply h-0;
}

.root .field .box {
  @apply w-5;
  @apply h-5;
  @apply flex;
  @apply rounded;
  @apply outline-2;
  @apply bg-gray-200;
  @apply items-center;
  @apply justify-center;
  @apply transition-colors;
  @apply outline-transparent;
}

.root .field .input:focus-visible + .box {
  @apply ring-2;
  @apply ring-offset-2;
  @apply ring-orange-600;
}

.root .field .box .mark {
  @apply w-4;
  @apply h-4;
  @apply opacity-0;
  @apply text-white;
  @apply transition-opacity;
}

.root .field .input:checked + .box {
  @apply bg-orange-500;
}

.root .field .input:checked + .box .mark {
  @apply opacity-100;
}

.root .label {
  @apply text-sm;
  @apply text-gray-700;
}
