.root {
  @apply grid w-full min-h-full;
  @apply lg:grid-cols-[1fr_3FR] lg:grid-flow-row lg:auto-rows-[120px_auto];
  @apply print:flex print:flex-col print:justify-between print:aspect-[20/29];
}

.root .head {
  @apply p-6 col-span-full flex flex-col items-center justify-end;
  @apply lg:pb-0 lg:order-1;
}

.root .head .title {
  @apply uppercase pb-4;
  @apply print:hidden;
}

.root .head .textScreen {
  @apply mx-auto text-lg text-gray-500 text-center;
  @apply print:hidden;
}

.root .head .textPrint {
  @apply hidden mx-auto text-gray-500 text-center;
  @apply print:block;
}

.root .resume {
  @apply p-6 flex flex-col items-center justify-center gap-3;
  @apply lg:px-0 lg:order-2 lg:gap-6 lg:mb-36;
}

.root .resume .infos {
  @apply flex flex-col items-center gap-1;
  @apply text-gray-700 text-center font-semibold;
}

.root .resume .chartContainer {
  @apply relative w-48 aspect-square flex items-center justify-center;
  @apply lg:w-64;
  @aaply print:w-48;
}

.root .resume .chartContainer .initiales {
  @apply absolute w-1/2 h-1/2 rounded-full flex items-center justify-center text-gray-700 text-3xl font-bold;
}

.root .content {
  @apply w-full p-6 flex flex-col-reverse my-auto gap-3;
  @apply lg:p-10 lg:gap-0 lg:order-3;
  @apply print:gap-0;
}

.logo {
  @apply w-40 hidden fixed top-5 left-10;
  @apply print:block;
}
