.root {
  @apply relative w-full p-5 flex flex-col;
  @apply lg:flex-row lg:px-10 lg:py-0;
  @apply print:px-5 print:py-0;
}

.root .background {
  @apply w-full h-full absolute z-0 inset-0 bg-current opacity-5 rounded-xl;
}

.root .head {
  @apply p-5 relative z-10 flex gap-1 flex-row-reverse justify-center items-center gap-4;
  @apply lg:w-1/4 lg:justify-center lg:flex-col lg:gap-0 lg:items-end;
  @apply print:p-0 print:flex-row-reverse print:justify-start print:items-center print:gap-0 print:h-20;
}

.root .head .title {
  @apply font-bold uppercase text-right;
  @apply print:pr-10 print:pl-4;
}

.root .head .lines {
  @apply hidden opacity-30 h-full w-1/2 overflow-hidden inset-0 grid-cols-3 divide-x divide-gray-500 border-x border-gray-500;
  @apply print:grid;
}

.root .scores {
  @apply relative z-10 flex flex-col flex-grow gap-6;
  @apply md:gap-0;
  @apply print:gap-0;
}
