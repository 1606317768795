.root {
  @apply w-full h-screen px-6 grid grid-rows-[1FR_auto_2FR] gap-2;
  @apply lg:grid-rows-1 lg:grid-cols-[1FR_2FR_1FR] lg:gap-20;
}

.desktopLeft {
  @apply order-1 hidden;
  @apply lg:flex lg:items-center;
}

.desktopLeft > * {
  @apply max-w-full h-auto;
}

.desktopRight {
  @apply order-3 hidden;
  @apply lg:flex lg:items-end;
}

.desktopRight > * {
  @apply max-w-full h-auto;
}

.mobileTop {
  @apply order-1;
  @apply lg:hidden;
}

.mobileBottom {
  @apply order-3 flex justify-center;
  @apply lg:hidden relative;
}

.mobileBottom > * {
  @apply absolute bottom-0 object-contain w-full max-h-full h-auto;
}

.middle {
  @apply order-2 auto-rows-min flex flex-col items-center justify-center gap-8;
}

.middle .hello {
  @apply text-center;
}

.middle .title {
  @apply text-center;
}

.middle .text {
  @apply text-center max-w-lg;
}

.middle .time {
  @apply text-center flex gap-2 items-center;
}

