@media print {
  html {
    font-size: 11px;
  }
  * {
    -webkit-print-color-adjust: exact;
  }
  @page {
    margin: 3em;
  }
}
