.root {
  @apply px-5 h-8 hidden relative items-center justify-start flex-row-reverse flex-shrink-0;
  @apply lg:flex lg:px-10;
  @apply print:flex;
}

.item {
  @apply w-1/6 h-full pr-3 border-r border-gray-300 flex items-start justify-end flex-shrink-0 text-xs uppercase font-medium text-gray-400
}

.item:not(:first-child) {
  @apply transform translate-x-px;
}
